import styled, { css } from 'styled-components';

import { StyledTabContainer } from '../SocialFeeds/styles';
import {CustomDropDown} from "../PeopleHomeV2/styles";
import { ImageUrl } from '../../utils/constants';

const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';

const OverlayDashboard = styled.div`
  opacity: 1;

  ${({showOverlay}) => showOverlay && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const DashboardLayout = styled.div`
  color: rgb(62,78,104);
  font-size: 30px;
  margin: ${({margin}) => margin ? margin : 'none'};
  // padding: 25px 25px 25px 25px;
  @media (max-width: 650px) {
    text-align: center;
    font-size: 20px;
  }
`;

const HeaderWrapper = styled.div`
  float: left;
  width: 100%;
  padding: ${(props) => props.padding ? '5px 37px 20px 32px' : '30px 0 0 0'};
  //-webkit-box-shadow: inset 3px 5.196px 5px rgba(0,0,0,0.08);
  //box-shadow: inset 3px 5.196px 5px rgba(0,0,0,0.08);
  @media (max-width: 500px) {
    padding: 30px 10px 20px;
  }
`;

const CardDashboard = styled.div`
  float: left;
  //background-color: red;
  width: 100%;
  display: block;
  // position: relative;
  // bottom: 12px;
  // @media (max-width: 1225px) and (min-width: 501px) {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }
  @media (max-width: 500px) {
    padding: 0 10px 20px;
  }
`;

const CardDashboardN = styled.div`
  float: left;
  //background-color: red;
  width: 100%;
  display: block;
  width: calc(100% / 2 - 12.5px);
  // position: relative;
  // bottom: 12px;
  // @media (max-width: 1225px) and (min-width: 501px) {
  //   display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-between;
  // }
  @media (max-width: 500px) {
    padding: 0 10px 20px;
  }
`;

const CardDashboardNew = styled.div`
  float: left;
  //background-color: red;
  width: 100%;
  display: block;
  // position: relative;
  // bottom: 12px;
  @media (max-width: 1225px) and (min-width: 501px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    padding: 0 10px 20px;
  }
`;

const CardContainer = styled.div`
  float: left;
  min-height: 140px;
  width: calc((100% - 75px)/4);
  display: inline-block;
  margin-right: 25px;
  background: #fff;
  padding: 0 10px;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 500px) {
    width: 100%;
    margin: 10px 0;
    padding: 0 10px;
  }
  
`;

const StyledTabs = styled(StyledTabContainer)`
float: none;
max-width: 1246px;
margin: auto;
   .main-container {
      display: block;
    @media (max-width : 700px){
      display: block;
    }
   }
  .tab-content {
    position: relative;
    
    @media (max-width: 600px) {
      margin-top: -48px;
    }
  }
  .dropdown-container{
    margin-left: 115px;
    // margin-top: -6px;
    height: 65px;
    > label {
      font-family: Rubik;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9C9C9C;
    margin-right: 10px;
    position: relative;
    //bottom: 44px;
    bottom: ${({role}) => (role === 'ADMIN') ? '3px' : '2px'};
    right: ${({role}) => (role === 'ADMIN') ? '275px' : '0px'};
    }
    @media (max-width: 700px) {
      margin-left: 0px;
    }
    @media (max-width: 510px) and (min-width: 501px) {
      margin-left: 30px;
    }
  }
  
  .company-container {
    float: right;
    margin-top: -49px;
    margin-right: 15px;
    position: relative;
    bottom: 31px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: 0 auto;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
   
  
  }
  
  > ul {
    padding: 15px 25px 15px 15px;
    @media (max-width: 500px) {
       min-height: ${({role}) => (role === 'ADMIN') ? '320px' : '270px'};
      display: block;
      text-align: center;
    }
    > li {
      @media (max-width: 600px) {
        float: none;
        width: 135px;
        margin: 0 auto;
      }
      > a {
				margin-right: 3vw;
        font-size: 16px;
        //color: rgb(51,51,51);
        color: #CCCBDO;
        text-transform: none;
        font-family: ${MediumFont};
        padding-bottom: ${({paddingBottom}) => paddingBottom ? '0px' : '17px'} 
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      // color: #0D4270;
      // font-family: Rubik-Medium;
      // width: 128px;
      // height: 40px;
      // background: rgba(0, 47, 71, 0.1);
      // border-radius: 3px;
      // font-style: normal;
      // font-weight: 500;
      // font-size: 18px;
      // line-height: 30px
      // text-align: center;
      // border-bottom: 3px solid  rgba(0, 47, 71, 0.1);

    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #0D4270;
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    //position: relative;
    //left: 15px;
     //margin-left: 10px;
    }
  }
  li.second-tab.active {
    > a {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #0D4270;
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }
  li.third-tab.active {
    > a {
      background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #0D4270;
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
      
  }
}
  li.fourth-tab.active {
    > a {
      background: rgba(0, 47, 71, 0.1);
      border-radius: 6px;
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      text-align: center;
      color: #0D4270;
      justify-content: center;
      padding: 10px;
      border-bottom: 3px solid  none;
      border: none;
      margin-top: -5px;
      
    }
  }
  .dropdown-menu {
    min-width: ${({newWidth}) => newWidth ? '0px' : '54px'};
    //min-width: 54px;
    //width: 67px;
    text-align: ${({newWidth}) => newWidth ? 'center' : 'none'};
    margin-left: ${({newWidth}) => newWidth ? '-62px' : '0px'};
     margin-top: -40px;
    > li {
        > a {
          padding: 5px 15px;
        }
      }
    > ul {
      max-width: 300px;
    }
    @media (max-width: 740px) and (min-width: 501px){
      margin-right: 38px;
    }
  }
`;

const Row1 = styled.div`
  float: left;
  width: 100%
  bottom: ${({paddingTop}) => paddingTop ? '8px' : '0' };
  padding: ${({padding}) => padding ? padding : '10px 0 0' };
  position: relative;
  //bottom: 8px;
  // @media (max-width: 1290px) and (min-width: 1225px) {
  //   max-width: 120px;  
  // }
  .textdata{
    font-size: 16px;
    float: left;
    margin: 0;
    text-transform: capitalize;
    color: #0D4270;
    padding: ${({padding}) => padding ? padding : '10px 0 0 17px' };
    //font-family: ${BoldFont}
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    line-height: 11px;
    //margin-top: 6px;
  }
  
  & > p {

      font-size: 12px;
    float: left;
    margin: 0px;
    text-transform: uppercase;
    color: rgb(111, 118, 126);
    padding: 13px 0px 0px 7px;
    line-height: 17px;
    font-family: Rubik-Medium;
    font-weight: 700;
       @media (max-width: 1290px) and (min-width: 1225px) {
       max-width: 120px;   
    }
  
  // .title_cls {
  //   p {
  //     font-size: 18px;
  //     float: left;
  //     margin: 0;
  //     text-transform: capitalize;
  //     color: #3A3A3A;
  //     padding: 13px 0 0 7px;
  //     //font-family: ${BoldFont}
  //     font-family: 'Rubik-Medium';
  //    font-style: normal;
  //    font-weight: 500;
  //    line-height: 20px;
  //     @media (max-width: 1290px) and (min-width: 1225px) {
  //       max-width: 120px;  
  //     }
  // }
  
  // .new_title_cls {
  //   p {
  //     font-size: 18px;
  //     float: left;
  //     margin: 0;
  //     text-transform: capitalize;
  //     color: #3A3A3A;
  //     padding: 13px 0 0 7px;
  //     //font-family: ${BoldFont}
  //     font-family: 'Rubik-Medium';
  //    font-style: normal;
  //    font-weight: 500;
  //    line-height: 20px;
  //     @media (max-width: 1290px) and (min-width: 1225px) {
  //       max-width: 120px;  
  //     }
  // }

  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: 50px;
    height: 50px;
    float: right;
  }
  .totaldata {
    float: left;
    padding-left: 5px;
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    font-size: ${({font}) => font ? '14px' : '16px' };
    font-family: 'Rubik-Regular';
    line-height: 24px;
    color: ${({ bgcolor }) =>  bgcolor ? bgcolor : '#fff'};
    >span{
      font-size: ${({font}) => font ? '14px' : '16px' };
      font-family: 'Rubik-Regular';
      color: ${({ bgcolor }) =>  bgcolor ? bgcolor : '#fff'};
      padding-left: 2px;
      line-height: 24px;
    }
  }
`;

const ImgContainer = styled.div`
    float: left;
    width: ${({width}) => width ? width : '52px' };
    height: ${({height}) => height ? height : '52px' };
    background-color: ${({bgColor }) => bgColor ? bgColor : '#fff'};
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    //position: relative;
    //right: 10px;
    //margin-top: 10px;
    //margin-left: 10px;
    & > img {
       width: 50px;
       height: 50px;
      
    }
`;

const NewImgContainer = styled.div`
   width: 100%;
   height: 7px;
   margin-top: 10px;
   background: ${({background}) => background ? background : 'pink'};
   border-radius: 11px;
}

div{

  > img {
    width: ${({width}) => width ? `${width}%` : '0%'};
    height: 7px; 
    margin: auto;
    float: left;
    border-radius: 11px
       }

}
    
     > img {
    width: ${({width}) => width ? `${width}%` : '0%'};
    height: 7px; 
    margin: auto;
    float: left;
    border-radius: 11px
       }
`;
const TotalCount = styled.div`
    font-size: 35px;
    margin-top: 10px;
    float: left;
    font-family: ${BoldFont};
    color: ${({color}) => color ? color : '#3e4e68'};
    // color: ${({ bgcolor }) =>  bgcolor ? bgcolor : '#fff'};
`;

const TotalCountNew = styled.div`
    font-size: 18px;
    margin-top: 10px;
    float: left;
    font-family: ${BoldFont};
    color: ${({ bgcolor }) =>  bgcolor ? bgcolor : '#fff'};
    line-height: 24px;
`;

const CSVButton = styled.button`
  //min-width: 130px;
  min-height: 40px;
  border-radius: 22px;
  background-color: rgba(159,201,137, 0.1);
  border: none;
  position: relative;
  float: right;
  margin-top: -38px;
  margin-right: ${({role}) => (role === 'ADMIN') ? '-40px' : '0px'};
  left: ${({role}) => (role === 'ADMIN') ? '' : '100px'};
  font-size: 11px;
  color: #3a3a3a;
  font-family: ${MediumFont};
  text-align: center;
  font-size: 16px;
  outline: none;
  padding: 10px;
  margin-top: -75px;
  border-radius: 8px;
  width: 13%;
  //width: ${({width}) => width ? width : '36%'};

  opacity: ${({disabled}) => disabled ? '0.5' : '1' };
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
  &:hover {
    color: #0169b2;
  }
  @media (max-width: 800px) {
    float: left;
    margin-left: 46px;
  }
  @media (max-width: 694px) {
   margin-top: ${({role}) => (role === 'ADMIN') ? '' : '-35px'};
  }
  @media (max-width: 650px) and (min-width: 601px){
   margin-top: ${({role}) => (role === 'ADMIN') ? '-56px' : ''};
  }
  @media (max-width: 600px){
     margin-top: ${({role}) => (role === 'ADMIN') ? '-56px' : '0px'};
  }@media (max-width: 552px){
     margin-top: ${({role}) => (role === 'ADMIN') ? '-56px' : '-30px'};
   }
  @media (max-width: 500px) {
    margin-top: ${({role}) => (role === 'ADMIN') ? '-130px' : '-6px'};
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .iyeGxG {
    margin-top: -50px;
  }
  .dHVzbH {
    width: 12%;
    min-height: 40px;
  }
  .glyphicon {
    margin-right: 5px;
  }
  img{
    width: 20px;
    height:
  }
  //.bsmdNs 
   //{
   // margin-top: -50px;
   // width: 13%;
   //}
   .bsmdNs {
     width: 13%;
   }
  
`;
const YearDropDown = styled(CustomDropDown)`
  float: right;
  align-items: center;
  margin-left: 60px;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  background-color: rgba(105, 194, 255, 0.1);
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  margin-right: 15px;
  padding: 10px 13px 10px 13px;
  .open>.dropdown-men{
    margin-top: -40px;
  }
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
  .caret{
    color: #69C2FF;
    float: left;
    margin-left :13px;
  }
`;
const DropDownCompanayContainer = styled.div`
  min-width: ${({width}) => width ? width : '310px'};
  width: auto;
  @media (max-width: 760px) {
    min-width: 310px;
  }
  @media (max-width: 650px) {
    min-width: 410px;
  }
  @media (max-width: 500px) {
    min-width: unset;
  }
`;

const CreateButton = styled.div`

`

const Button = styled.div`
  float: right;
  margin-top: 30px;
`;
const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #9C9C9C;
margin-right: 10px;
margin-bottom: 0px;
margin-top: 10px;
`;

const Row2 = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
  & > div:first-child {
    width: 50px;
    height: 50px;
    >img{
      width: 100%;
      height: 100%;
    }
  }
  & > div:last-child {
    width: calc(100% - 50px);
    padding-left: 15px;
    display: block;
    margin: auto;
    & > div:first-child {
      width: 100%;
 
      font-size: 16px;
      color: #005C87;
      font-family: 'Rubik-Medium';
    }
    & > div:last-child {
      width: 100%;
 
      font-size: 16px;
      color: #005C87;
      font-family: 'Rubik-Medium';
    }
  }
`;

const TopChallengesWrapper = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 justify-content:space-between;
 margin: ${({margin}) => margin ? margin : ''};
`;

const ChallengeCompletedWrapper = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 justify-content: space-between;
 margin-top: 25px;
 >div:nth-child(1){
  width: calc((100% - 50px) / 3)
 }
 >div:nth-child(2){
  width: calc((100% - 50px) / 3)
}
>div:nth-child(3){
  width: calc((100% - 50px) / 3)
}
`;

const ChallengeCompletedCardMain = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
 flex-wrap: wrap;
 background: #fff;
 border-radius: 6px;
`;

const ChallengeCompletedTop = styled.div`
 width: 100%;

 display: flex;
 border-bottom: 1px solid #005C874D;
 & > div:first-child {
   display: flex;
   width: 40px;
   height: 40px;
   margin: 15px 10px 15px 15px;
   ?img{
     width: 100%;
     height: 100%;
   }
 }
 & > div:last-child {
   display: flex;
   margin: auto;
   margin-left: 0px;

   font-family: 'Rubik-Medium';
   font-size: 18px;
   line-height: 24px;
   color: #005C87;
}
`;

const ChallengeCompletedCard = styled.div`
 width: 100%;
 display: flex;
 >div{
   width: 100%;
   height: 100%;
   display: flex;
   margin: 0px 15px 0px 15px;
   padding: 10px 0px;
   border-top: ${({border}) => border ? border : '' };
   & > div:first-child {
     width: 60%;

     font-family: Rubik;
     font-size: 16px;
     line-height: 30px;
     color:#005C87;
     margin: auto;
   }
   & > div:last-child {
     width: 40%;
     height: 100%;
     display: flex;
     justify-content: flex-end;
     >div{
      width: 50%;
      height: 38px;
      display: flex;
      border-radius: 6px;

      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 19px;
      color:#005c87;
      background: ${({background}) => background ? background : 'pink' };
      justify-content: center;
      align-items: center;
     }
  }
 }
`;

const TopTenCardWrapper = styled.div`
 width: 100%;
 height: 100%;
 display: flex;
//  margin-top: 25px;
>div:nth-child(1){
  width: calc((100% - 25px) / 2);
  margin-top: 25px;
 }
 >div:nth-child(2){
  width: calc((100% - 25px) / 2);
  margin-left: 25px;
  margin-top: 25px;
}
`;

const TopTenParticipantWrapper = styled.div`
 width: 100%;
 display: block;
 background: #fff;
 border-radius: 6px;
`;

const TopTenParticipantTop = styled.div`
 width: 100%;
 display: flex;
 padding: 10px 0px 10px 15px;
 background: #fff;
 border-radius: 6px 6px 0 0;

 >div:nth-child(1){
   width: 80%;
   margin: auto;

   font-family: 'Rubik-Medium';
   font-size: 18px;
   line-height: 24px;
   color:#005C87;
 }
 >div:nth-child(2){
   width: 20%;
   display: flex;
   justify-content:flex-end;
   margin-right: 15px;

   >img{
     width: 32px;
     height: 32px;
     cursor: pointer;
   }
  }
`;

const TopTenParticipantHeading = styled.div`
 width: 100%;
 display: flex;
 padding: 15px 0px 10px 15px;
 background: #005C870D;
 padding: 3px 0px 3px 0px;
 >div:nth-child(1){
width: 40%;
padding-left: 15px;

font-family: 'Rubik-Regular';
font-size: 16px;
line-height: 24px;
color: rgba(0, 92, 135, 0.60);
 }
 >div:nth-child(2){
  width: 20%;
  display: flex;
  justify-content: center;

font-family: 'Rubik-Regular';
font-size: 16px;
line-height: 24px;
color: rgba(0, 92, 135, 0.60);
}
>div:nth-child(3){
  width: 20%;
  display: flex;
  justify-content: center;

font-family: 'Rubik-Regular';
font-size: 16px;
line-height: 24px;
color: rgba(0, 92, 135, 0.60);
}
>div:nth-child(4){
  width: 20%;
  display: flex;
  justify-content: center;

font-family: 'Rubik-Regular';
font-size: 16px;
line-height: 24px;
color: rgba(0, 92, 135, 0.60);
}
`;

const TopTenParticipantContent = styled.div`
 width: 100%;
 display: flex;
 background: #fff;
 border-radius: ${({borderRadius}) => borderRadius ? '6px' : '' };
 >div{
   margin: 0px 10px 0px 15px;
   border-top: ${({border}) => border ? border : '' };
   width: 100%;
   display: flex;
 >div:nth-child(1){
width: 40%;
>div{
  width: 100%;
  display: flex;
  .profile{
    >img{
    width: 50px;
    height: 50px;
    border-radius: 6px;
    margin: 15px 0px 10px 0px;
  }

  }
  >img{
    width: 50px;
    height: 50px;
    border-radius: 6px;
    margin: 15px 0px 10px 0px;
  }
  >div{
    align-items: center;
    margin: flex;
    display: flex;
    flex-wrap: wrap;
    margin: auto 0px;
    margin-left: 10px;
    >div{
      width: 100%;
      margin-top: 5px;
  >img{
    width: 111px;
    height: 13px;
    display: flex;
    margin-top: 5px;
  }
>span{
  font-family: Rubik;
font-size: 10px;
line-height: 9px;
color: #fff;
margin-top: -11px;
display: flex;
justify-content: flex-start;
margin-left: 10px;
}}
  >div{   width: 100%;
    margin-top: 0px;
  >p{

font-family: 'Rubik-Medium';
font-size: 14px;
line-height: 16.2px;
color: #005C87;
display: flex;
text-align:center;
align-items: center;
margin-bottom: 0px;
}
  }}
 }
}
 >div:nth-child(2){
  width: 20%;
  display: flex;
  justify-content: center;

font-family: 'Rubik-Regular';
font-size: 14px;
line-height: 16px;
color: #005C87;
align-items: center;
}
>div:nth-child(3){
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Rubik-Regular';
  font-size: 14px;
  line-height: 16px;
  color: #005C87;
}
>div:nth-child(4){
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Rubik-Regular';
  font-size: 14px;
  line-height: 16px;
  color: #005C87;
}
}
`;

const LaunchButton = styled.div`
  width: 19%;
  max-width: 235px;
  height: 40px;
  background: linear-gradient(90deg, rgb(0, 111, 188) -1.11%, rgb(127, 67, 255) 97.46%);
  display: flex;
  border-radius: 6px;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  margin-right: 25px;
  margin-top: -80px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DownloadButtonWrapper = styled.div`
  display: flex;
  margin: auto;
  margin-right: 0px;
  // margin-top: -80px;
  .dropdown-menu{
    min-width:100px;
  }
  div{
    .dropdown{
      .sub-menu-wrapper{
        width: 250px;
        background: white;
        z-index:99999;
        left: 40px;
        border: 1px solid rgba(0,92,135,0.30);
        border-radius: 6px;
        padding: 12px 0px;
        box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
        .sub-menu{
          color: #007AB1;
          font-family: Rubik-medium;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          padding: 4px 0px 4px 12px;
          cursor:pointer;
        }
      }
      button{
        background: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-top: ${(isEvent)=> isEvent && '3px'};
        ${'' /* padding-left: ${(isEvent)=> isEvent && '0px'}; */}
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 0px;
        margin-left: 0px;
        &:active{
          background: none;
          border: none;
        }
      }
      .dropdown-menu.show{
        display: inline-flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        transform: translate3d(-13px, 50px, 0px);
        border-radius: 4px;
        border: 1px solid rgba(0, 92, 135, 0.30);
        background: #FFF;
        display: flex;
        flex-direction: column;
        margin-left: -175px;
        a{
          color: #007AB1;
          font-family: Rubik-medium;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display:flex;
          >svg{
            display:flex;
            margin:auto 0 auto auto;
            transform:rotate(0deg);
          }
          &:active{
            background: none;
          }
        }
        hr{
          width: 90%;
          color: rgba(0, 92, 135, 0.50);
        }
      }
    }
  }
`;

const ChallengesWrapper = styled.div`
  width: 100%;
  display: flex;
  >div:nth-child(1){
    width: 67%;
  }
  >div:nth-child(2){
    width: 33%;
    padding-left: 25px;
  }
`;

const ActiveChallengesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
`;

const OverviewWrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-wrap: wrap;
`;

const LaunchchallengeCards = styled.div`
  width: 100%;
  display: flex;
  margin-top: 25px;
  min-height: 170px;
  background: #76AB7833;
  border-radius:6px;
  .image{
    display:flex;
    height:140px;
    margin:auto 30px auto 25px;
    >div{
      width: 100%;
      display: flex;
    >img{
      width:100%;
      height:140px;
      margin: auto;
    }}}
  .text {
    display: block;
    margin: auto auto auto 0px;
    >p{
      font-family: 'Rubik-Regular';
      font-size: 18px;
      line-height: 24px;
      color: #005C87;
      margin:0px;
      >span{
        font-family: 'Rubik-Medium';
        font-size: 18px;
        line-height: 24px;
        color: #005C87;
      }
      @media(max-width: 1200px) {
        >span{
        font-size: 18px;
        }
      }
    }
    @media(max-width: 1200px) {
      >p{
        font-size: 18px;
      }
    }
    >span{
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 24px;
      color: #FFF;
    }
  }
  > div:last-child {
    margin: auto 40px auto auto;
  }
`;

const LaunchchallengeButton = styled.div`
  width: ${({width}) => width ? width : '100%' };
  height: 54px;
  background: #005c87;
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  max-width: 399px;
  justify-content: center;
  box-shadow: 1px 4px 10px 0px #005C8766;
  padding:15px;
  > div:first-child {
    display: flex;
    margin: auto 0;
    >img{
      max-width: 22px;
      max-height: 22px;
    }
    svg{
      max-width: 22px;
      max-height: 22px;
      >path{
        fill:white;
      }
    }
  }
  > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: white;
    padding-left:10px;
  }
`;

const GlobalChallengesWrapper = styled.div`
  width: 100%;
  display: flex;
  font-family: 'Rubik-Medium';
  font-size: 18px;
  line-height: 24px;
  color: #005c87;
  justify-content: center;
  align-items: center;
`;

const TopCardEngagement = styled.div`
  width: calc((100% - 75px)/4);
  max-width:288px;
  display:flex;
  background:white;
  border-radius:6px;
  padding:15px 0 15px 15px;
  flex-wrap:wrap;
  .first-child{
    display:flex;
    width:100%;
    ${'' /* margin-bottom:25px; */}
    .image{
      width:48px;
      height:48px;
      margin-right:10px;
      background: ${({background}) => background ? background : '#005c87'}; 
      display:flex;
      border-radius:3px;
      >img{
        width:24px;
        height:24px;
        display:flex;
        margin:auto;
      }
    }
    .title{
      font-family: Rubik-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color:#005c87;
      display:flex;
      margin:auto 0px;  
    }
    .infoIcon{
      width: 22px;
      height:22px;
      display:flex;
      margin:auto 15px auto auto;
      cursor:pointer;
      >img{
        width:100%;
        height:100%;
      }
    }
  }
  .progressCard{
    width:100%;
    display:flex;
    margin-bottom:10px;
    .percent{
      font-family: Rubik-Medium;
      font-size: 30px;
      font-weight: 500;
      line-height: 32px;
      text-align: left;
      color: #85C0EA;
      margin-right:5px;
      align-items: end;
      display:flex;
    }
    .percentText{
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: rgba(0, 92, 135, 0.60);
      display:flex;
      margin:auto auto 0 0;
    }
  }
  .progressBar{
    width:100%;
    margin-bottom: 10px;
    padding-right:15px;
  }
  .last-child{
    width:100%;
    display:flex;
    .totalParticipant{
      font-family: Rubik-Medium;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: ${({color}) => color ? color : '#005c87'};
      align-items: center;
      display: flex;
      padding-right:3px;
    }
    .participateText{
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: rgba(0, 92, 135, 0.60);
      align-items: center;
      display: flex;
      padding-right:3px;
    }
  }
  .challengeData{
    width:100%;
    display:flex;
    margin-top:24px;
    .value{
      font-family: Rubik-Medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: ${({color}) => color ? color : '#005c87'};
      margin-right:12px;
      align-items:center;
      display:flex;
      ${'' /* min-width:48px; */}
    }
    .valueText{
      font-family: Rubik;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color:rgba(0, 92, 135, 0.60);
      display:flex;
      margin:auto auto auto 0;
      align-items: center;
    }
  }
  .borderCard{
    background: #005C871A;
    width: 108px;
    height:1px;
    margin: 15px 0 10px 0;
  }

`;

const ChallengeCardWrapper = styled.div`
  width: 100%;
  margin: ${({margin}) => margin ? margin : '60px 0 35px 0'};
  .siglneCardmain{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 24px;
  }
`;

const CoachCreationMainDiv = styled.div`
  border: 1px solid black;
  display: flex;
  
  .headerDiv{
    background-color: white;
    
    .headerCloseIcon{
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 122, 177, 0.05);
      
      span{

      }
    }

    .headerText{
      color: #005C87;
      text-align: center;
      font-family: Rubik-medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 133.333% */
    }
  
  }
`;

const RecommendedContainer = styled.div`
// margin:15px 0px 30px 0px;
width:1248px;
.row{
  display:flex;
  justify-content:center;
  width:100%;
  margin:0;
  .col-1{
    background:white;
    border-radius: 6px 0 0 6px;
    width: ${({width}) => width ? "534px" : '496px'};
    padding: ${({width}) => width && '0px'};
    .type{
      display:flex;
      margin:20px;

    .type-text{
      border-radius: 6px;
      background: rgba(0, 92, 135, 0.60);
      color:#FFF;
      font-family: Rubik-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; 
      padding:5px 15px;
      text-transform:capitalize;
     }
    }

    .details{
      display:flex;
      flex-direction:column;
      justify-content:center;
      height:100%;
      margin-left: ${({width}) => width ? "20px" : '44px'};
      .title{
        color:  #005C87;
        font-family: Rubik-Medium;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .challengeType{
        width:100%;
        display:flex;
        margin-top:25px;
        .icon{
          height:24px;
          width:auto;
          display:flex;
          >img{
            width:100%;
            height:100%;
          }
        }
        .type{
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: center;
          color: #005c87;
          display:flex;
          align-items:center;
          margin:0;
          padding-left:10px;
        }
      }
      .description{
        color:  #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 28px 0px;
        -webkit-line-clamp: 8;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
      }
      .titleWrapper{
        color:  #005C87;
        font-family: Rubik;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin: 12px 0px;
        -webkit-line-clamp: 8;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box !important;
        overflow: hidden;
        >div{
          color: #005C87;
          font-family: Rubik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          width:100%;
          margin: 16px 0;
          list-style-type: disc;
          display:flex;
          .bullet{
            font-size: 36px;
            color: #005C87;
            padding-right:8px;
          }
        }
      }
      .moving-btn{
        align-items:center;
        flex-shrink: 0;
        display:flex;
        button{
          width: 352px;
          height: 48px;
          border-radius: 4px;
          background: #007AB1;
          ${'' /* box-shadow: 0px 8px 24px 0px rgba(0, 92, 135, 0.50); */}
          color: #FFF;
          text-align: center;
          font-family: Rubik-Medium;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          border:none;
          flex-shrink: 0;
        }
        .fav-icon{
          border-radius: 3px;
          background:  rgba(0, 92, 135, 0.05);
          padding:13px;
          margin-left:15px;
        }
      }
    }
  }

  .col-2{
    background:${({background})=> background ? `linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 93.33%),url(${ImageUrl}/${background})` : 'red'};
    background-size : cover;
    background-repeat : no-repeat;
    background-size: 100% 100%;
    width: ${({width}) => width ? "714px" : '750px'};
    height:450px;
    border-radius: 0px 6px 6px 0px;
    .pick{

      margin-right:16px;

      .title{
      color: #005C87;
      text-align: center;
      font-family: Rubik-Medium;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    }
  }

  .imageContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

  .imageWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
  }
}
`

export { OverlayDashboard, DashboardLayout, CardDashboard, CardContainer, HeaderWrapper,
  StyledTabs, Row1, ImgContainer, TotalCount, CSVButton, YearDropDown, DropDownCompanayContainer, CreateButton,NewLabel,
  NewImgContainer, Button, CardDashboardNew, TotalCountNew, CardDashboardN, Row2, TopChallengesWrapper, ChallengeCompletedWrapper, 
  ChallengeCompletedCardMain, ChallengeCompletedTop, ChallengeCompletedCard, TopTenCardWrapper, TopTenParticipantWrapper, TopTenParticipantTop, 
  TopTenParticipantHeading, TopTenParticipantContent, LaunchButton, ButtonWrapper, DownloadButtonWrapper, ChallengesWrapper, ActiveChallengesWrapper, 
  OverviewWrapper, LaunchchallengeCards, LaunchchallengeButton, GlobalChallengesWrapper, TopCardEngagement, ChallengeCardWrapper, CoachCreationMainDiv, RecommendedContainer };